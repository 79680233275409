import React from 'react';
import { graphql } from 'gatsby';
import 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Callout from '~components/Callout';
import CaptionImage from '~components/CaptionImage';
import Text from '~components/Text';
import Button from '~components/Button';

import { roundedImg } from '~theme';

import { VARIANT } from '../enums';

export const query = graphql`
  {
    strategicPlanBooklet: file(relativePath: { eq: "strategic-plan-2022-2025-booklet.pdf" }) {
      publicURL
    }
  }
`;

const AboutPage: React.FC<PageProps> = ({ data }) => {
  return (
  <Layout>
    <SEO title="About Mohawk" desc="" pathname="/about" />
    <Hero title="About Mohawk">
      <StaticImage src="../images/mohawk-building-overhang.jpg" alt="" layout="fullWidth" />
    </Hero>
    <div tw="container">
      <div tw="grid gap-12 md:(grid-cols-3)">
        <div tw="md:(col-span-2)">
          <Text>
            Mohawk College educates and serves more than 32,500 full-time, part-time, apprenticeship
            and international students at three main campuses in Hamilton, Ontario and learning hubs
            across Hamilton through City School by Mohawk, and at the College’s Centre for Aviation
            Technology at the Hamilton International Airport. Mohawk is among the top five colleges
            for applied research in Canada.
          </Text>
          <Callout variant={VARIANT.ORANGE}>
            <Text tw="my-0 text-2xl font-bold">
              Mohawk College has been named one of Canada’s greenest employers seven years in a row,
              holds a GOLD STARS rating from AASHE for sustainability achievements and is home to
              the country’s largest and first institutional building to receive dual certification
              for Zero Carbon Building Framework design and performance for The Joyce Centre for
              Partnership &amp; Innovation. More than 130,000 people have graduated from Mohawk
              since it was founded.
            </Text>
          </Callout>
          <Text>
            Mohawk College is a founding partner of the Canadian Colleges for a Resilient Recovery –
            a coalition of institutions across Canada to support a new climate-focused economic
            recovery.
          </Text>
          <Text>
            <Button variant="red" to={data.strategicPlanBooklet.publicURL}>
            Download a copy of the Strategic Report in an Accessible PDF format
            </Button>
          </Text>
        </div>
        <div tw="md:(col-span-1)">
          <CaptionImage variant={VARIANT.GREEN} caption="Interior view of Mohawk College.">
            <StaticImage
              style={roundedImg}
              src="../images/mohawk-interior-stairs.jpg"
              alt=""
              layout="fullWidth"
            />
          </CaptionImage>
        </div>
      </div>
      <Text>
        For more information on Mohawk College’s Strategic Plan, please contact the Office of the
        President at{' '}
        <a
          tw="text-red underline hover:(no-underline text-red-700)"
          href="mailto:strategicplan@mohawkcollege.ca"
        >
          strategicplan@mohawkcollege.ca
        </a>
        .
      </Text>
    </div>
  </Layout>
  );
};

export default AboutPage;
